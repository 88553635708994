<template>
  <modal class="sellOutEditModel" :isShow="isShow">
    <div class="header">沽清操作</div>
    <div class="modal-body">
        <div class="clearfix">
            <div class="left-box">
                <div class="tr">
                    <span class="text-label">菜品名称：{{data?.Eat_XFNAME}}</span>
                </div>
                <div class="tr">
                    <span class="text-label">沽清数量：</span>
                    <div class="tally-box">
                        <div class="bnt-jian" @click="del()"><b class="iconfont icon-jian"></b></div>
                        <div class="num">
                            <input-pattern
                                ref="numInput"
                                v-model="sellOutNum" pattern="number" :min="0" :dplaces="2" readonly
                            ></input-pattern>
                        </div>
                        <div class="bnt-jia" @click="add()"><b class="iconfont icon-jia"></b></div>
                    </div>
                </div>
                <div class="tr">
                    <span class="text-label">限售时段：</span>
                    <el-select v-model="salesValue" placeholder="全天" :disabled="sellOutNum==0">
                        <el-option value="" label="全天"></el-option>
                        <el-option
                        v-for="item in eatPhases"
                        :key="item.EAT_PHASEID"
                        :label="item.EAT_PHASENAME"
                        :value="item.EAT_PHASEID">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="right-box">
                <div class="keyboard-box">
                    <div class="title-lable"></div>
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-box">
        <button class="btn btn-cancel" @click="hide()">取消</button>
        <button class="btn btn-confirm" @click="confirm()">确认</button>
    </div>
  </modal>
</template>

<script>
import {initKeyBoard} from '/src/common'
/**沽清操作 */
export default {
    name:'sellOutEditModel',
    props:{
        isShow:Boolean,
        /**菜品数据 */
        data:Object
    },
    data(){
        return{
            eatPhases:[],
            /**沽清数量 */
            sellOutNum:0,
            /**限售时段： */
            salesValue:''
        }
    },
    mounted(){
        this.$nextTick(()=>{
            initKeyBoard.call(this,this.$el,this.$refs.numInput.$el);
            this.init();
        })
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        },
        sellOutNum(newValue){
            if(newValue>0 && this.data.Is_Limited===false){
                
                this.$nextTick(()=>{
                    this.sellOutNum=0;
                })
                this.$message.warning('非限量销售菜品不能设置数量！');
            }
        }
    },
    methods:{
        /**初始化数据 */
        init(){
            this.sellOutNum=this.data?.Limited_Num||0;//限量销售数量
            this.salesValue=this.data?.EAT_PHASEID;//限量时段ID
            this.$nextTick(()=>{
                this.$refs.numInput.$el.focus();
            })
            this.loadData();
        },
        /**加载时段数据 */
        loadData(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$cacheData.EatPhase().then((d)=>{
                loading.close();
                if(d){
                    this.eatPhases=d;
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('加载时段数据失败：'+e,);
                console.log('加载时段数据失败：'+e);
            })
        },
        hide(){
            this.$emit("closeModel");
        },
        confirm(){
            // if(this.data.SoldOut_ID && !(this.sellOutNum>0)){
            //     this.$message.warning('沽清数量必须大于0！');
            //     return;
            // }
            let eatPhase={};
            if(this.sellOutNum>0 && this.salesValue){
                eatPhase=this.eatPhases?.find(t=>t.EAT_PHASEID==this.salesValue);
            }
            this.$emit("confirm",{
                SoldOut_ID:this.data.SoldOut_ID,//估清信息ID
                Eat_XFKINDNAME:this.data.Eat_XFKINDNAME,//类别名称
                Eat_XFBMID:this.data.Eat_XFBMID,//菜品ID
                Eat_XFNAME:this.data.Eat_XFNAME,//菜品名称
                Eat_XFCODE:this.data.Eat_XFCODE,
                PINYIN:this.data.PINYIN,
                Limited_Num:this.sellOutNum||0,//限量销售数量
                EAT_PHASEID:eatPhase.EAT_PHASEID||"",//时段ID
                EAT_PHASENAME:(this.sellOutNum!=0 && eatPhase.EAT_PHASEID)?eatPhase.EAT_PHASENAME:'全天'//时段名称
            });
        },
        /**沽清数量加*/
        add(){
            this.sellOutNum=parseFloat((parseFloat(this.sellOutNum)+1).toFixed(2));
        },
        /**沽清数量减*/
        del(){
            if(this.sellOutNum-1>=0){
                this.sellOutNum=parseFloat((parseFloat(this.sellOutNum)-1).toFixed(2));
            }else{
                this.sellOutNum=0;
            }
        }
    }
}
</script>

<style lang='scss'>
@import './sellOutEditModel.scss'
</style>